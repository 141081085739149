import React from 'react';
import './App.css';

const AT_LEAST = "Vsaj";
const EXACT = "Točno";
const FIXED = "Fiksno";

const settings = [
    { label: "Nacionalne ŠZ", baseVotes: 2, mode: AT_LEAST, perc: 51, people: 39, minPeople: 1, maxPeople: 39 },
    { label: "Lokalne ŠZ", baseVotes: 1, mode: EXACT, perc: 33.33, people: 36, minPeople: 1, maxPeople: 36 },
    { label: "Drugi", baseVotes: 1, mode: FIXED, perc: 0, people: 45, minPeople: 0, maxPeople: 45 },
    // { label: "Drugi", baseVotes: 1, mode: FIXED, perc: 0, people: 40, minPeople: 0, maxPeople: 40 },
    // { label: "Olimp. športnika", baseVotes: 2, mode: FIXED, perc: 0, people: 2, minPeople: 0, maxPeople: 2 },
];

function errFunc(mode, target) {
    switch (mode) {
        case FIXED: return () => 0;
        case EXACT: return (val) => Math.pow(target - val, 2);
        case AT_LEAST: return (val) => val >= target ? 0 : Math.pow(target - val, 2);
    }
}

function totalError(state) {
    let totalVotes = 0;
    for (let row of state)
        totalVotes += row.peeps * row.votes;

    let totalError = 0;
    for (let row of state)
        totalError += row.err(row.peeps * row.votes / totalVotes);

    return totalError;
}

function solve(present) {
    let state = settings.map((sett, index) => ({
        baseVotes: sett.baseVotes,
        votes: sett.baseVotes,
        mode: sett.mode,
        target: sett.perc / 100,
        peeps: present[index],
        err: errFunc(sett.mode, sett.perc / 100)
    }));

    // let iters = 5000;
    let delta = 0.0005;

    while (delta > 0.000001) {
        let error = totalError(state);
        if (error <= 1E-12)
            break;

        let bestIndex = -1;
        let bestDelta = 0;
        let bestError = error;

        for (let index = 0; index < state.length; index++) {
            let row = state[index];
            if (row.mode === FIXED)
                continue;

            let initialVotes = row.votes;

            row.votes = initialVotes + delta;
            let alterError = totalError(state);
            if (alterError < bestError) {
                bestError = alterError;
                bestIndex = index;
                bestDelta = delta;
            }

            if (initialVotes - delta >= row.baseVotes) {
                row.votes = initialVotes - delta;
                alterError = totalError(state);
                if (alterError < bestError) {
                    bestError = alterError;
                    bestIndex = index;
                    bestDelta = -delta;
                }
            }

            row.votes = initialVotes;
        }

        if (bestIndex >= 0) {
            let oldVotes = state[bestIndex].votes;
            state[bestIndex].votes += bestDelta;
            if (state[bestIndex].votes === oldVotes)
                break;
        } else {
            delta /= 10.0;
        }
    }

    return state;
}

function perc(num) {
    return (num * 100).toFixed(4) + " %";
}

function perc2(num) {
    return (num * 100).toFixed(2) + " %";
}

function formatNum(num) {
    return num.toFixed(4);
}

function formatMode(row) {
    switch (row.mode) {
        case FIXED:
            return "Vedno " + row.baseVotes + " glas";

        case EXACT:
            return "Točno " + perc(row.perc / 100);

        case AT_LEAST:
            return "Vsaj " + perc(row.perc / 100);
    }
}

function generateCases(present) {
    let fixedIndices = [];
    let fixedMaxes = [];
    let fixedMins = [];

    for (let i = 0; i < settings.length; i++) {
        let row = settings[i];
        if (row.mode !== FIXED)
            continue;

        fixedIndices.push(i);
        fixedMins.push(row.minPeople);
        fixedMaxes.push(row.maxPeople);
    }

    let result = [];
    let seen = [];

    let counts = generateFixedCounts(fixedMins, fixedMaxes, 0, [ [] ]);

    for (let count of counts) {
        let sum = 0;
        for (let i = 0; i < fixedIndices.length; i++)
            sum += count[i] * settings[fixedIndices[i]].baseVotes;
        if (seen[sum])
            continue;
        seen[sum] = true;

        let row = [...present];
        for (let i = 0; i < fixedIndices.length; i++)
            row[fixedIndices[i]] = count[i];
        result.push(row);
    }

    return result;
}

function generateFixedCounts(mins, maxes, idx, prev) {
    if (idx >= mins.length)
        return prev;

    let result = [];
    for (let zis = mins[idx]; zis <= maxes[idx]; zis++) {
        for (let p of prev) {
            let c = [ ...p ];
            c.push(zis);
            result.push(c);
        }
    }

    return generateFixedCounts(mins, maxes, idx + 1, result);
}

class App extends React.Component {
    state = {
        present: settings.map(it => it.people)
    };

    updatePresent(index, delta) {
        let newNum = this.state.present[index] + delta;

        if (newNum >= settings[index].minPeople && newNum <= settings[index].maxPeople) {
            let newPresent = [ ...this.state.present ];
            newPresent[index] += delta;
            this.setState({ present: newPresent });
        }
    }

    render() {
        let { present } = this.state;

        let sol = solve(present);

        let totalVotes = 0;
        for (let row of sol)
            totalVotes += row.votes * row.peeps;

        let numPresent = 0;
        for (let n of present)
            numPresent += n;


        return (
            <div className="App">
                <div className="splitter">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Kdo</th>
                                <th>Glasov</th>
                                <th>Način</th>
                                <th>Vseh</th>
                            </tr>
                        </thead>
                        <tbody>
                            { settings.map((sett, index) => (
                                <tr>
                                    <th>{ index + 1 }.</th>
                                    <td style={{ fontWeight: 'bold' }}>{ sett.label }</td>
                                    <td>{ sett.baseVotes }</td>
                                    <td>{ formatMode(sett) }</td>
                                    <td className="num">{ sett.maxPeople } </td>
                                </tr>
                            ))}

                            <tr>
                                <td className="dummy" colSpan={ 4 } />
                                <td className="num">{ numPresent }</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="arrow">
                        @
                    </div>

                    <table>
                        <thead>
                        <tr>
                            <th colSpan={ 3 }>Prisotnih</th>
                        </tr>
                        </thead>
                        <tbody>
                        { settings.map((sett, index) => (
                            <tr>
                                <td style={{ borderRight: 'none' }}>
                                    <button onClick={ this.updatePresent.bind(this, index, -1) }>
                                        -
                                    </button>
                                </td>
                                <td style={{ borderLeft: 'none', borderRight: 'none', fontWeight: 'bold' }}>
                                    { present[index] }
                                </td>
                                <td style={{ borderLeft: 'none' }}>
                                    <button onClick={ this.updatePresent.bind(this, index, 1) }>
                                        +
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <div className="arrow">
                        =
                    </div>


                    <table>
                        <thead>
                        <tr>
                            <th>Glasov na osebo</th>
                            <th>Skupaj glasov</th>
                            <th>Procent</th>
                        </tr>
                        </thead>
                        <tbody>
                        { settings.map((sett, index) => (
                            <tr>
                                <td className="num">{ formatNum(sol[index].votes) }</td>
                                <td className="num">{ formatNum(sol[index].votes * sol[index].peeps) }</td>
                                <td className="num">{ perc(sol[index].votes * sol[index].peeps / totalVotes) }</td>
                            </tr>
                        ))}

                        <tr>
                            <td className="dummy" />
                            <td className="num">{ formatNum(totalVotes) }</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br /><br />
                <div>
                    <table>
                        <thead>
                            <tr>
                                { settings.map((row, index) => (
                                    <th key={ index }>#{ index + 1 }</th>
                                )) }
                                { settings.map((row, index) => (
                                    <th key={ index }>Glasov #{ index + 1 }</th>
                                )) }
                                { settings.map((row, index) => (
                                    <th key={ index }>Procent #{ index + 1 }</th>
                                )) }
                            </tr>
                        </thead>
                        <tbody>
                            { generateCases(present).map(pr => {
                                let data = solve(pr);
                                let totalVotes = 0;
                                for (let row of data)
                                    totalVotes += row.peeps * row.votes;

                                return (
                                    <tr>
                                        { settings.map((row, index) => (
                                            <td key={ index }>{ pr[index] }</td>
                                        )) }

                                        { settings.map((row, index) => (
                                            <td key={ index }>{ formatNum(data[index].votes) }</td>
                                        )) }

                                        { settings.map((row, index) => (
                                            <td key={ index }>{ perc2(data[index].votes * data[index].peeps / totalVotes) }</td>
                                        )) }
                                    </tr>
                                );
                            }) }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default App;
